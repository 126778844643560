import React from "react"
import { Link } from "gatsby"
import TagLinks from "./TagLinks"

const Article = ({ node, random = true }) => {
  const title = node.frontmatter.title || node.fields.slug

  const span = random && Math.random() * 100 <= 20 ? 2 : 1
  return (
    <article
      style={{
        gridColumn: `span ${span}`,
      }}
    >
      <header>
        <h4>
          <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
            {title}
          </Link>
        </h4>
        <p>
          <TagLinks tags={node.frontmatter.tags} />
        </p>
        <small>{node.frontmatter.date}</small>
      </header>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      </section>
      <Link to={node.fields.slug}>Read More...</Link>
    </article>
  )
}
export default Article
