import React from "react"
import _ from "lodash"
import { Link } from "gatsby"

const TagLinks = ({ tags }) => (
  <>
    {tags.map((t, i) => (
      <>
        {i > 0 && ", "}
        <Link to={`/tags/${_.kebabCase(t)}`}>{t}</Link>
      </>
    ))}
  </>
)

export default TagLinks
