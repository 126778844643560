import styled from "styled-components"
import { rhythm } from "../utils/typography"
const SeriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: ${rhythm(3 / 4)};
  grid-auto-flow: row dense;
  padding-top: ${rhythm(3 / 4)};

  article {
    border: 1px solid #aaa;
    border-radius: 10px;
    background-color: var(--articleColor);
    position: relative;
    header {
      padding: ${rhythm(2 / 4)};
      color: white;
      border-radius: 10px 10px 0px 0px;
      background-color: var(--seriesHeadColor);
      h4 {
        margin-bottom: 0;
        min-height: ${rhythm(1.5)};
        font-weight: 700;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      p {
        margin-bottom: 0;
      }
    }
    section {
      padding: ${rhythm(2 / 4)};
    }
    > a {
      position: absolute;
      bottom: ${rhythm(1 / 4)};
      padding-right: ${rhythm(2 / 4)};
      text-decoration: none;
      color: inherit;
      width: 100%;
      text-align: right;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`

export default SeriesGrid
