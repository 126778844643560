import React from "react"
import { rhythm } from "../utils/typography"
import { Link } from "gatsby"
import styled from "styled-components"

const HeaderContainer = styled.header`
  grid-column: 3;
  h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    font-family: "Calligraffitti";
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
  }
  h3 {
    font-family: "Calligraffitti";
    margin-top: 0;
    margin-bottom: 0;
  }
  h5 {
    font-family: "Calligraffitti";
  }
`

const Header = ({ title, root }) => {
  let header
  if (root) {
    header = (
      <h1>
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3 style={{}}>
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }

  return (
    <HeaderContainer>
      <h5 style={{ marginLeft: "-2rem", marginBottom: 0 }}>It gives you...</h5>
      {header}
      <h5 style={{ textAlign: "right", marginBottom: root ? rhythm(1.5) : 0 }}>
        Erotica by Joe Tortuga
      </h5>
    </HeaderContainer>
  )
}

export default Header
