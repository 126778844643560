import Typography from "typography"

const theme = {
  baseFontSize: "20px",
  baseLineHeight: 1.5,
  headerFontFamily: ["Quando"],
  bodyFontFamily: ["Raleway", "sans-serif"],
  googleFonts: [
    {
      name: "Calligraffitti",
      styles: ["400"],
    },
    {
      name: "Raleway",
      styles: ["400", "400i", "700", "700i"],
    },
    {
      name: "Quando",
      styles: ["400", "400i", "700", "700i"],
    },
  ],
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
