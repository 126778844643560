import React from "react"
import { rhythm } from "../utils/typography"
import Header from "./header"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
:root {
  --bgColor: #e4e6fb;
  --headColor: #1e266d;
  --articleColor: white;
  --seriesHeadColor:  rgba(140, 38, 109, 1);
}
  body {
    background-color: var(--bgColor)
  }
`

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr ${rhythm(24)} 1fr 1fr;
  padding-top: ${rhythm(3 / 4)};
`

const Main = styled.main`
  grid-column: ${({ root }) => (root ? "2 / span 3" : "3")};
`
const Footer = styled.footer`
  grid-column: 3;
`
/*
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(3 / 4)} ${rhythm(3 / 4)}`,
        }}
*/
const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  return (
    <LayoutGrid>
      <GlobalStyle />
      <Header root={location.pathname === rootPath} title={title} />
      <Main
        root={
          location.pathname === rootPath ||
          location.pathname.includes("/tags/") ||
          location.pathname.includes("/series/")
        }
      >
        {children}
      </Main>
      <Footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </Footer>
    </LayoutGrid>
  )
}

export default Layout
